<template>
  <v-data-table
    :headers="headers"
    :items="company_users"
    sort-by="name"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat class="pt-2">
        <v-col cols="12" sm="8" class="mt-5">
          <v-toolbar-title
            class="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
          >
            <v-icon class="mr-2 mt-n1" color="error"
              >mdi-account-group</v-icon
            >Company User
          </v-toolbar-title>
          <p class="grey--text ml-8 mt-n2">List of all system users</p>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" align="right">
          <v-row justify="center">
            <v-dialog v-model="dialog" scrollable max-width="550px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2 text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus-thick</v-icon> New
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="error--text text--darken-1">{{
                    formTitle
                  }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="companyName"
                          label="Company Name"
                          :rules="nameRules"
                          disabled
                          outlined
                          dense
                        ></v-text-field>

                        <v-text-field
                          v-model="editedItem.name"
                          label="User Name *"
                          :rules="nameRules"
                          outlined
                          dense
                          color="grey"
                        ></v-text-field>

                        <v-text-field
                          v-model="editedItem.email"
                          label="Email *"
                          :rules="emailRules"
                          outlined
                          dense
                          color="grey"
                        ></v-text-field>

                        <v-text-field
                          v-model="editedItem.phone"
                          label="Phone"
                          :rules="nameRules"
                          outlined
                          dense
                          color="grey"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-divider class="mt-n5"></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    class="text-capitalize mx-1"
                    dark
                    @click="close"
                  >
                    Cancel <v-icon small>mdi-cancel</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize mx-1"
                    :loading="loading"
                    @click="save"
                  >
                    Submit <v-icon>mdi-content-save-outline</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
      </v-toolbar>
      <v-divider></v-divider>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ formatDate(item.create_at) }}
    </template>
    <template v-slot:[`item.company`]="{ item }">
      {{ getCompanyName(item.company) }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="my-1">
        <v-btn
          color="grey"
          class="mx-1"
          fab
          x-small
          dark
          @click="editItem(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          color="error"
          class="mx-1"
          fab
          x-small
          dark
          @click="deleteItem(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import UserService from "../../services/user.service";
import moment from "moment";

export default {
  data: () => ({
    valid: true,
    passwordRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    emailRules: [
      (v) => !!v || "Valid E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    dialog: false,
    headers: [
      {
        text: "User Name",
        align: "start",
        filterable: true,
        value: "name",
      },
      {
        text: "Company Name",
        value: "company",
      },
      {
        text: "Email Address",
        value: "email",
      },
      {
        text: "Phone",
        value: "phone",
      },
      {
        text: "Date created",
        value: "created_at",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    company_users: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      company: {
        company_name: "",
      },
      company_id: "",
      email: "",
      phone: "",
    },
    defaultItem: {
      name: "",
      company: {
        company_name: "",
      },
      company_id: "",
      email: "",
      phone: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    companyName() {
      return this.company_name;
    },
    companyId() {
      return this.company_id;
    },
    loading() {
      return this.$store.state.loading;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getAllUsers();
    this.getAllUsers();
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    this.editedItem.company_id = this.user.company.id;
    console.log(this.editedItem.company_id);
    this.company_name = this.user.company.company_name;
    console.log(this.company_name);
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDate(input) {
      return moment(input).format("Do MMMM  YYYY, h:mm:ss a");
    },
    getCompanyName(company) {
      return company.company_name;
    },
    getAllUsers() {
      return UserService.getAllCompanyUsers().then(
        (response) => {
          console.log(response.data);
          if (response.status == 200) {
            this.company_users = response.data.data;
            console.log(response.data);
          } else {
            this.company_users = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.company_users = [];
          console.log(error);
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.company_users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      console.log(item.id);
      const index = this.company_users.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.company_users.splice(index, 1)) {
        UserService.deleteUser(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$store.dispatch("setLoading", false);
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$store.dispatch("setLoading", true);
        if (this.editedIndex > -1) {
          Object.assign(this.company_users[this.editedIndex], this.editedItem);
          this.editedItem.company_name = this.editedItem.company.company_name;
          UserService.updateUser(this.editedItem).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.$store.dispatch("setLoading", false);
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.$store.dispatch("setLoading", false);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
              this.$store.dispatch("setLoading", false);
            }
          );
        } else {
          // create user
          this.editedItem.company_name = this.$store.state.auth.user.company.company_name;
          this.editedItem.company = this.$store.state.auth.user.company;
          this.editedItem.company_id = this.$store.state.auth.user.company_id;
          this.editedItem.callback = process.env.VUE_BASE_URL+"login#/reset-password";
          UserService.storeUser(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.editedItem = response.data.data;
                this.$store.dispatch("alert/success", response.data.message);
                this.$store.dispatch("setLoading", false);
                this.getAllUsers();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.$store.dispatch("setLoading", false);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("setLoading", false);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>
